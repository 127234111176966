import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Formik, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

import Seo from "../components/seo"

import { fieldErrorMessage } from "../shared/inputTypes"

const Contact = ({ location, pageContext }) => {
  const lan = pageContext.locale
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          en {
            submit
            selectFile
          }
          de {
            submit
            selectFile
          }
        }
      }
    }
  `)

  const contactFormSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, lan === "en" ? "Name is required" : "Name ist erforderlich")
      .trim()
      .max(100, "Name can not not be greater than 100 Characters")
      .required(lan === "en" ? "Name is required" : "Name ist erforderlich"),
    email: Yup.string()
      .email(
        lan === "en"
          ? "Email must be a valid email"
          : "E-Mail muss eine gültige E-Mail sein"
      )
      .required(lan === "en" ? "Email is required" : "Email ist erforderlich")
      .strict(true),
    subject: Yup.string()
      .min(1, lan === "en" ? "Subject is required" : "Betreff ist erforderlich")
      .trim()
      .max(200, "Subject Can not not be greater than 100 Characters")
      .required(
        lan === "en" ? "Subject is required" : "Betreff ist erforderlich"
      ),
    description: Yup.string()
      .min(
        1,
        lan === "en"
          ? "Description is required"
          : "Beschreibung ist erforderlich"
      )
      .trim()
      .max(400, "Description Can not not be greater than 100 Characters")
      .required(
        lan === "en"
          ? "Description is required"
          : "Beschreibung ist erforderlich"
      ),
  })

  let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  // const [fileName, setFileName] = useState(
  // 	lan === "en" ? "No file chosen" : "Keine Datei ausgewählt"
  // )
  // const [fileError, setFileError] = useState("")

  // const onSelectFile = () => {
  // 	const uploadField = document.getElementById("customFile")
  // 	setFileName(uploadField.files[0].name)
  // 	if (uploadField.files[0].size > 25000000) {
  // 		setFileError(
  // 			lan === "en"
  // 				? "Oops! File is too big (max-size : 25Mb )"
  // 				: "Hoppla! Datei ist zu groß (max. Größe: 25 MB)"
  // 		)
  // 		uploadField.value = ""
  // 	} else {
  // 		setFileError("")
  // 	}
  // }

  // const removeFile = () => {
  // 	const uploadField = document.getElementById("customFile")
  // 	uploadField.value = ""
  // 	setFileName(lan === "en" ? "No file chosen" : "Keine Datei ausgewählt")
  // 	setFileError("")
  // }

  const initialValues = {
    name: "",
    email: "",
    subject: "",
    description: "",
    department: "",
    attachment: {},
  }

  return (
    <>
      <Seo
        lan={lan}
        title={lan === "en" ? "Contact Us" : "Kontaktiere uns"}
        description={
          lan === "en"
            ? "How can we help you? Send us a message and we will get in contact."
            : "Wie können wir Ihnen helfen? Senden Sie uns eine Nachricht und wir werden uns mit Ihnen in Verbindung setzen."
        }
        url={`${site.siteMetadata.siteUrl}/${lan}/${pageContext.url}`}
        titleTemplate={
          lan === "en"
            ? "Contact Us: HaackSchubert - Lawyers, tax consultants, auditors and notaries"
            : "Kontaktiere uns: HaackSchubert - Rechtsanwälte, Steuerberater, Wirtschaftsprüfer und Notare"
        }
      />
      <div className="contact-form no--margin-menu padding--menu-sw">
        <h1 style={{ fontWeight: "500", marginBottom: "45px", marginTop: 0 }}>
          {lan === "en"
            ? "How can we help you?"
            : "Wie können wir Ihnen helfen?"}
        </h1>
        <Formik
          initialValues={initialValues}
          validateOnBlur={true}
          validateOnChange={true}
          validationSchema={contactFormSchema}
        >
          {({ values }) => (
            <form
              encType="multipart/form-data"
              method="post"
              name="contact"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              style={{ fontFamily: '"Source Sans Pro", sans-serif' }}
            >
              <input type="hidden" name="form-name" value="contact" />
              <div className="contact-input">
                <div className="sub-contact-input">
                  <Field type="text" placeholder="*Name" name="name" />
                  <ErrorMessage name="name" component={fieldErrorMessage} />
                </div>
                <div className="sub-contact-input">
                  <Field type="email" placeholder="*Email" name="email" />
                  <ErrorMessage name="email" component={fieldErrorMessage} />
                </div>
              </div>
              <div className="contact-input">
                <div className="sub-contact-input">
                  <Field
                    type="text"
                    placeholder={lan === "en" ? "*Subject" : "*Betreff"}
                    name="subject"
                  />
                  <ErrorMessage name="subject" component={fieldErrorMessage} />
                </div>
                <div
                  className="teammember-search contact-dro"
                  style={{
                    minWidth: "48.5%",
                    margin: "0 auto 0 auto",
                  }}
                >
                  <div className="search dropdown contact-fo">
                    <select
                      id="divisionfilter"
                      className="select-svg team-dr"
                      name="department"
                      defaultValue={
                        location.state && location.state.service
                          ? location.state.service
                          : ""
                      }
                    >
                      <option value="">
                        {lan === "en"
                          ? "Select Service"
                          : "Dienstleistung auswählen"}
                      </option>
                      <option>
                        {lan === "en" ? "Legal Advice" : "Rechtsberatung"}
                      </option>
                      <option>{lan === "en" ? "Notaries" : "Notare"}</option>
                      <option>
                        {lan === "en" ? "Tax Consultancy" : "Steuerberatung"}
                      </option>
                      <option>
                        {lan === "en" ? "Auditing" : "Wirtschaftsprüfung"}
                      </option>
                      <option>
                        {lan === "en"
                          ? "Real Estate Consulting"
                          : "Immobilienberatung"}
                      </option>
                      <option>
                        {lan === "en"
                          ? "Management Consultancy"
                          : "Unternehmensberatung"}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="sub-contact-textarea">
                <Field
                  type="text"
                  placeholder={lan === "en" ? "*Description" : "*Beschreibung"}
                  name="description"
                  rows="4"
                  cols="160"
                  component="textarea"
                  className="contact-desc"
                  autoComplete="off"
                />
                <ErrorMessage
                  name="description"
                  component={fieldErrorMessage}
                />
              </div>
              <div style={{ padding: "35px 15px" }}>
                {values.name.trim() &&
                values.email.trim() &&
                values.subject.trim() &&
                values.description.trim() &&
                emailPattern.test(values.email) ? (
                  <button
                    disabled={false}
                    className="submit-button outline-none"
                    type="submit"
                  >
                    {lan && site.siteMetadata && site.siteMetadata[lan].submit}
                  </button>
                ) : (
                  <button
                    className="submit-button outline-none bg-grey cursor-not-allowed"
                    disabled={true}
                    type="submit"
                  >
                    {lan && site.siteMetadata && site.siteMetadata[lan].submit}
                  </button>
                )}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default Contact
