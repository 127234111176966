import React from "react"

/**
 * common input field component
 * @param props { field, form: { touched, errors }, ...props }
 */
const renderInput = props => {
  return (
    <>
      <input
        style={{ fontWeight: "lighter" }}
        type={props.type}
        label={props.label}
        name={props.field.name}
        value={props.field.value}
        placeholder={props.placeholder}
        className={props.className}
        onChange={props.field.onChange}
        onBlur={props.field.onBlur}
        autoComplete={props.autoComplete}
        multiple={props.multiline ? props.multiline : false}
        disabled={props.disabled ? props.disabled : false}
      />
    </>
  )
}

/**
 * common field error message component
 * @param props
 */
const fieldErrorMessage = props => (
  <span style={{ color: "red", textAlign: "left", float: "left" }}>
    {props.children}
  </span>
)

export { renderInput, fieldErrorMessage }
